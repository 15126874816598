export class Criterias {

  dateRestrict: string;
  exactTerms: string;
  excludeTerms: string;

  constructor(dateRestrict: string, exactTerms: string, excluseTerms: string) {

    this.dateRestrict = dateRestrict;
    this.exactTerms = exactTerms;
    this.excludeTerms = excluseTerms;
  }
}
