<div>
  <table mat-table [dataSource]="displayedItems" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- Crée toutes les colonnes à partir de l'objet columnCollections  -->
    <ng-container matColumnDef={{column.name}} *ngFor="let column of columnCollections">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-raised-button color="primary" [ngClass]="isActive(column.name) ? '' :'not-active'"
          (click)="handleButtonFilterClick(column.name)">{{column.label}}</button>
      </th>
      <td mat-cell *matCellDef="let element">{{element[column.name]}}</td>
    </ng-container>

    <!-- imageExisting Column -->
    <ng-container matColumnDef="images">
      <th mat-header-cell *matHeaderCellDef>
        IMAGES<br />
        <ng-container *ngFor="let index of indexes">
          <mat-checkbox #check [disabled]="checkBoxesDisabled" title="Colonne {{ index + 1 }}"
            (change)="onClickCheck(check, index)">
          </mat-checkbox>
        </ng-container>
        <br />
        IMAGES REELLES<br />
        <ng-container *ngFor="let index of indexes">
          <mat-checkbox #check [disabled]="checkBoxesDisabled" title="Colonne Réelle {{ index + 1 }}" 
              (change)="onClickRealCheck(check, index)">
          </mat-checkbox>
        </ng-container>
      </th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngFor="let image of element.images; let index = index">
          <img #img (mouseover)="mouseOver(img)" (mouseout)="mouseOut()" [id]="element.index + '_' + index"
            (click)="toggleSelection(element.index, index)" [src]="image.link" class="image-thumbnail" />
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<div [hidden]="hidden"><img id="zoom" [src]="src"></div>
