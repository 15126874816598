import { HttpClient, HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { concatMap, map, tap, toArray } from 'rxjs/operators';
import { Criterias } from '../classes/criterias';
import { Item } from '../classes/item';
import { GoogleImage } from '../classes/googleImage';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchGoogleService {

  selectedColumns: Array<string>;

  constructor(private http: HttpClient) { }

  search(searched: Item[], selectedColumns: Array<string>, criterias: Criterias, selectedCountry: string, nbMedias: number): Observable<Array<GoogleImage[]>> {

    const exactTermsQuery = criterias.exactTerms ? `&extactTerms=${criterias.exactTerms}` : '';
    const excludeTermsQuery = criterias.excludeTerms ? `&excludeTerms=${criterias.excludeTerms}` : '';
    const dateRestrictQuery = criterias.dateRestrict ? `&dateRestrict=${criterias.dateRestrict}` : '';
    const country = `&gl=${selectedCountry}`;
    const finalCriterias = `${exactTermsQuery}${excludeTermsQuery}${dateRestrictQuery}${country}`;

    this.selectedColumns = selectedColumns;

    const urls = searched.map((current: Item) => this.buildURL(current, finalCriterias, nbMedias));

    // On fait autant d'appels à Google que nécessaire
    return from(urls).pipe(
      concatMap((url: string) => this.http.get(url)),
      map(
        (objPrinc: any) => {

          const total = parseInt(objPrinc?.searchInformation?.totalResults, 10);
          if (total > 0) {
            return objPrinc?.items.map((object: any) => {
              return new GoogleImage(object.image, object.link);
            });
          } else {
            return null;
          }
        }
      ),
      toArray()
    );
  }

  buildURL(item: Item, criterias: string, nbMedias: number): string {

    const configUrl = this.getFullUrlFromEnv(0, nbMedias);

    const httpUrlEncoding = new HttpUrlEncodingCodec();

    let queryParams = '&q=';
    queryParams += this.selectedColumns.find( (elem) => elem === 'reference') ? item.reference ? httpUrlEncoding.encodeValue(item.reference) : '' : '';
    queryParams += this.selectedColumns.find( (elem) => elem === 'season') ? item.season ? (queryParams === '&q=' ? '' : '+') + httpUrlEncoding.encodeValue(item.season) : '' : '';
    queryParams += this.selectedColumns.find( (elem) => elem === 'year') ? item.year ? (queryParams === '&q=' ? '' : '+') + httpUrlEncoding.encodeValue(item.year) : '' : '';
    queryParams += this.selectedColumns.find( (elem) => elem === 'brand') ? item.brand ? (queryParams === '&q=' ? '' : '+') + httpUrlEncoding.encodeValue(item.brand) : '' : '';
    queryParams += this.selectedColumns.find( (elem) => elem === 'gender') ? item.gender ? (queryParams === '&q=' ? '' : '+') + httpUrlEncoding.encodeValue(item.gender) : '' : '';
    queryParams += this.selectedColumns.find( (elem) => elem === 'articleCode') ? item.articleCode ? (queryParams === '&q=' ? '' : '+') + httpUrlEncoding.encodeValue(item.articleCode) : '' : '';
    queryParams += this.selectedColumns.find( (elem) => elem === 'category') ? item.category ? (queryParams === '&q=' ? '' : '+') + httpUrlEncoding.encodeValue(item.category) : '' : '';
    queryParams += this.selectedColumns.find( (elem) => elem === 'product') ? item.product ? (queryParams === '&q=' ? '' : '+') + httpUrlEncoding.encodeValue(item.product) : '' : '';
    queryParams += this.selectedColumns.find( (elem) => elem === 'description') ? item.description ? (queryParams === '&q=' ? '' : '+') + httpUrlEncoding.encodeValue(item.description) : '' : '';
    queryParams += this.selectedColumns.find( (elem) => elem === 'color') ? item.color ? (queryParams === '&q=' ? '' : '+') + httpUrlEncoding.encodeValue(item.color) : '' : '';

    return `${configUrl}${queryParams}${criterias}`;
  }

  getFullUrlFromEnv(startIndex: number = 0, count: number = 5): string {

    return `${environment.googleApiUrl}?key=${environment.googleApiKey}&cx=${environment.googleApiSearchCx}&startIndex=${startIndex}&num=${count}&searchType=image`;
  }
}
