import { Component, OnInit, SecurityContext } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SearchGoogleService } from 'src/app/services/search-google.service';

import countries from 'src/app/fixtures/countries';
import { criterias } from 'src/app/classes/config';
import { Image } from 'src/app/classes/image';
import { GoogleImage } from 'src/app/classes/googleImage';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'app-table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.css']
})
export class TableFiltersComponent implements OnInit {

  countries = countries;
  selectedCountry: string;
  nbSamples: number = 1;
  nbMedias: number = 3;

  uploadDone: boolean;
  scrappingDone: boolean;

  spinnerHidden: boolean = true;

  constructor(
    private googleService: SearchGoogleService,
    private sanitizer: DomSanitizer,
    private dataService: DataService,
    private communicationService: CommunicationService) {

  }

  ngOnInit(): void {

    this.selectedCountry = countries[72].code;
    this.uploadDone = false;
    this.scrappingDone = false;

    this.dataService.pushNbMedias(this.nbMedias);

    // Si jamais on change de fichier d'upload, on charge les données dans la grille
    this.dataService.onNewData().subscribe(

      () => {

        this.uploadDone = true;
        this.scrappingDone = false;
      }
    );
  }

  onChange(): void {

    if (this.nbMedias > 10 || this.nbMedias < 1) {

      this.nbMedias = 3;
    }
    this.dataService.pushNbMedias(this.nbMedias);
  }

  launch(sample: boolean): void {

    this.spinnerHidden = false;
    let data = this.dataService.getData();
    if (sample) {

      data = data.slice(0, this.nbSamples);
    }

    const selectedColumns = this.dataService.getSelectedColumns();
    this.googleService.search(data, selectedColumns, criterias, this.selectedCountry, this.nbMedias).subscribe(

      (result: Array<GoogleImage[]>) => {

        for (let i = 0; i < data.length; i++) {
          data[i].images = new Array<Image>();
          let images = result[i];
	  
          if (images !== null) {

            images.forEach((element) => {

              let image = new Image(element.thumbnailLink, element.realLink);
              data[i].images.push(image);
            });
          }
        }
        if (!sample) {

          this.scrappingDone = true;
          this.dataService.pushScrappingDone();
        }
        this.spinnerHidden = true;
      },
      (error) => {

        this.communicationService.pushError(new Error("Une erreur s'est produite !!!"));
        console.error(error);

        this.spinnerHidden = true;
      },
      () => this.spinnerHidden = true
    );
  }

  download(): void {

    const link = document.createElement("a");
    const dataForCsv = this.dataService.getDataForCsv();
    const blob = new Blob([dataForCsv], { type: 'application/text-csv' });

    link.download = this.dataService.getNewFileName();
    const fileUrl = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(
      window.URL.createObjectURL(blob)
    ));

    link.href = fileUrl;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
