import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  errorQueue = new Subject<Error>  ();

  constructor(private http: HttpClient) { }

  pushError(error: Error): void {
    this.errorQueue.next( error );
  }

  onError(): Observable<Error> {
    return this.errorQueue.asObservable();
  }
}
