export class GoogleImage {

  contextLink: string;
  height: number;
  width: number;
  byteSize: number;
  thumbnailLink: string;
  thumbnailHeight: number;
  thumbnailWidth: number;
  realLink: string

  constructor(obj: object, realLink: string) {

    this.realLink = realLink;
    this.contextLink = obj['contextLink'];
    this.height = obj['height'];
    this.width = obj['width'];
    this.byteSize = obj['byteSize'];
    this.thumbnailLink = obj['thumbnailLink'];
    this.thumbnailHeight = obj['thumbnailHeight'];
    this.thumbnailWidth = obj['thumbnailWidth'];
  }
}
