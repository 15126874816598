import { Criterias } from './criterias';
import { TableColumn } from './tableColumn';

const columnsArray: TableColumn[] = [
  {
    name: 'reference',
    selected: true,
    label: 'REFERENCE'
  },
  {
    name: 'season',
    selected: false,
    label: 'SEASON'
  },
  {
    name: 'year',
    selected: false,
    label: 'YEAR'
  },
  {
    name: 'brand',
    selected: false,
    label: 'BRAND'
  },
  {
    name: 'gender',
    selected: false,
    label: 'GENDER'
  },
  {
    name: 'articleCode',
    selected: false,
    label: 'ARTICLE CODE'
  },
  {
    name: 'category',
    selected: false,
    label: 'CATEGORY'
  },
  {
    name: 'product',
    selected: false,
    label: 'PRODUCT'
  },
  {
    name: 'description',
    selected: false,
    label: 'DESCRIPTION'
  },
  {
    name: 'color',
    selected: false,
    label: 'COLOR'
  }
];

// Les colonnes a afficher dans le tableau
export const displayedColumns = [
  'reference',
  'season',
  'year',
  'brand',
  'gender',
  'articleCode',
  'category',
  'product',
  'description',
  'color',
  'images'
];

export const criterias = new Criterias('', '', '');
export default columnsArray;
