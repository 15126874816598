import { Component, OnInit } from '@angular/core';
import { Item } from 'src/app/classes/item';
import { CommunicationService } from 'src/app/services/communication.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {

  fileToUpload: File;
  fileName: string = 'Aucun fichier sélectionné';

  constructor(
    private communicationService: CommunicationService,
    private dataService: DataService) { }

  ngOnInit() {

    this.fileToUpload = null;
  }

  handleFileInput(files: FileList) {

    this.fileToUpload = files.item(0);
    if (this.fileToUpload) {

      this.fileName = this.fileToUpload.name;
      document.getElementById('submit').click();
    }
  }

  onSubmit(): void {

    let fileReader = new FileReader();
    fileReader.onload = (e) => {

      const data = fileReader.result.toString();
      const result = [];
      let lines = data.split('\r\n');
      if (lines.length > 10001) {

        this.communicationService.pushError(new Error('Le fichier est trop grand !'));
        return;
      }
      lines = lines.slice(1);
      for (let i = 0; i < lines.length; i++) {

        let item = new Item();
        item = item.fromString(i, lines[i]);
        if (item !== null) {

          result.push(item);
        } else if (lines[i].trim() == '' && i !== (lines.length - 1)) {

          this.communicationService.pushError(new Error(`Le fichier est incorrect ! (Problème ligne ${i + 2})`));
          return;
        } else if (lines[i].trim() !== '') {

          this.communicationService.pushError(new Error(`Le fichier est incorrect ! (Problème ligne ${i + 2})`));
          return;
        }
      }

      this.dataService.pushData(this.fileToUpload.name, result);
    }
    fileReader.readAsText(this.fileToUpload);
  }
}

