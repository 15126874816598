import { Image } from "./image";

export class Item {

  index: number;
  reference: string;
  season: string;
  year: string;
  brand: string;
  gender: string;
  articleCode: string;
  category: string;
  product: string;
  description: string;
  color: string;
  images: Array<Image>;

  constructor() {

    this.index = -1;
    this.reference = '';
    this.season = '';
    this.year = '';
    this.brand = '';
    this.gender = '';
    this.articleCode = '';
    this.category = '';
    this.product = '';
    this.description = '';
    this.color = '';
    this.images = new Array<Image>();
  }

  fromString(index: number, line: string) {

    const fields = line.split(';');
    if (fields.length != 10) {

      return null;
    }

    this.index = index;
    this.reference = fields[0];
    this.season = fields[1];
    this.year = fields[2];
    this.brand = fields[3];
    this.gender = fields[4];
    this.articleCode = fields[5];
    this.category = fields[6];
    this.product = fields[7];
    this.description = fields[8];
    this.color = fields[9];

    return this;
  }
}
