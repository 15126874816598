import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { Item } from 'src/app/classes/item';

import { SearchGoogleService } from 'src/app/services/search-google.service';
import columnsArray, { criterias, displayedColumns } from 'src/app/classes/config';
import { TableColumn } from 'src/app/classes/tableColumn';
import { DataService } from 'src/app/services/data.service';
import { getMatIconFailedToSanitizeUrlError } from '@angular/material/icon';

@Component({
  selector: 'app-product-table',
  templateUrl: './product-table.component.html',
  styleUrls: ['./product-table.component.css']
})

export class ProductTableComponent implements OnInit, AfterViewInit {

  hidden: boolean = true;
  src: string = '';

  // Colonnes affichées dans le tableau, configurable dans config.ts
  displayedColumns: string[] = displayedColumns;

  // Permet d'ajouter des filtres et des parametres au scrapping, configurable dans config.ts
  criterias = criterias;

  // Correspond au lignes du tableau
  displayedItems = null;

  nbMedias: number;
  indexes: Array<number>;
  checkBoxesDisabled: boolean = true;

  // permet d'enlever les références et de cloner l'objet
  // Objet qui nous permet d'afficher les colonnes du tableau et de les activers ou non.
  // Configurable dans config.ts
  columnCollections: TableColumn[] = JSON.parse(JSON.stringify(columnsArray));

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private dataService: DataService) {

  }

  ngAfterViewInit(): void {

    if (this.displayedItems != null) {

      this.displayedItems.sort = this.sort;
      this.displayedItems.paginator = this.paginator;
    }
  }

  ngOnInit(): void {

    // Si jamais on change de fichier d'upload, on charge les données dans la grille
    this.dataService.onNewData().subscribe(

      (data) => {

        this.displayedItems = new MatTableDataSource<Item>(data);
      }
    );

    this.dataService.onNbMediasChange().subscribe(

      (nbMedias) => {
        this.nbMedias = nbMedias;
        this.indexes = new Array();
        for (let i = 0; i < this.nbMedias; i++) {

          this.indexes.push(i);
        }
      }
    )

    this.dataService.onScrappingDone().subscribe(

      (done) => this.checkBoxesDisabled = false
    )
  }

  /**
   *
   * Regarde si une colonne est activée pour le scrapping
   * @param key
   * @returns
   */
  isActive = (key: string) => {

    const col = this.columnCollections.find(x => x.name === key);
    return col.selected;
  }

  /**
   * Modifie l'état actif ou non d'une colonne, dont l'id est passée en param
   * @param key
   */
  handleButtonFilterClick(key: string): void {

    const col = this.columnCollections.find(x => x.name === key);
    col.selected = !col.selected;

    if (col.selected) {

      this.dataService.addColumn(key);
    } else {

      this.dataService.removeColumn(key);
    }
  }

  toggleSelection(lineIndex: number, imageIndex: number) {

    const img = document.getElementById(lineIndex + "_" + imageIndex);
    if (img.classList.contains('selected')) {

      img.classList.remove('selected');
      this.dataService.updateSelection(lineIndex, imageIndex, false);
    } else {

      img.classList.add('selected');
      this.dataService.updateSelection(lineIndex, imageIndex, true);
    }
  }

  mouseOver(img) {

    const element = document.getElementById('zoom');

    element.setAttribute('src', img.src);
    element.style.left = '50%';
    element.style.top = '50%';

    this.hidden = false;
  }

  mouseOut() {

    this.hidden = true;
  }

  onClickCheck(checkbox, index) {

    const data = this.dataService.getData();
    let img;
    if (data) {

      for (let i = 0; i < data.length; i++) {

        img = document.getElementById(i + "_" + index);
        if (!img) {

          continue;
        }
        if (checkbox.checked) {

          img.classList.add('selected');
          this.dataService.updateSelection(i, index, true);
        } else {

          img.classList.remove('selected');
          this.dataService.updateSelection(i, index, false);
        }
      }
    }
  }

  onClickRealCheck(checkbox, index) {

    const data = this.dataService.getData();
    let img;
    if (data) {

      for (let i = 0; i < data.length; i++) {

        img = document.getElementById(i + "_" + index);
        if (!img) {

          continue;
        }
        if (checkbox.checked) {
          this.dataService.updateRealSelection(i, index, true);
        } else {
          this.dataService.updateRealSelection(i, index, false);
        }
      }
    }
  }
}
