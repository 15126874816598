import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { CommunicationService } from './services/communication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'Scrapper';
  communicationSubscription: Subscription;

  constructor(
    private communicationService: CommunicationService,
    private titleService: Title,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {

    this.titleService.setTitle(this.title);
    this.communicationService.onError().subscribe(

      (error) => this.snackBar.open(error.message, 'Fermer')
    );
  }

  ngOnDestroy(): void {

    if (this.communicationSubscription != null) {

      this.communicationSubscription.unsubscribe();
      this.communicationSubscription = null;
    }
  }

}
