export class Image {

  link: string;
  selected: boolean;
  realSelected: boolean;
  realLink: string

  constructor(link: string, realLink: string) {

    this.realLink = realLink;
    this.link = link;
    this.selected = false;
    this.realSelected = false;
  }
}
