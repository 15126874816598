import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Item } from '../classes/item';
import columnsArray from 'src/app/classes/config';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  protected dataQueue = new Subject<Array<Item>>();
  protected mediasQueue = new BehaviorSubject<number>(1);
  protected scrappingQueue = new Subject<boolean>();

  protected fileName: string;
  protected data: Array<Item>;
  protected selectedColumns: Array<string>;

  constructor() {

    this.selectedColumns = columnsArray.filter((element) => element.selected).map((element) => element.name);
  }

  public pushData(fileName: string, data: Array<Item>): void {

    this.fileName = fileName;
    this.data = data;
    this.dataQueue.next(data);
  }

  public getFileName(): string {

    return this.fileName;
  }

  public getNewFileName(): string {

    const fileNameSplitted = this.fileName.split('.');
    const extention = fileNameSplitted.pop();
    const newFileName = fileNameSplitted.join('.') + '.new.' + extention;

    return newFileName;
  }

  public onNewData(): Observable<any> {

    return this.dataQueue.asObservable();
  }

  public getData(): Array<Item> {

    return this.data;
  }

  public getDataForCsv(): string {

    let dataForCsv = 'reference;season;year;brand;gender;articleCode;category;product;description;color;image1;image_reelle_1;image2;image_reelle_2;image3;image_reelle_3;image4;image_reelle_4;image5;image_reelle_5;image6;image_reelle_6;image7;image_reelle_7;image8;image_reelle_8;image9;image_reelle_9;image10;image_reelle_10\r\n';

    this.data.forEach((element) => {

      dataForCsv += element.reference + ';';
      dataForCsv += element.season + ';';
      dataForCsv += element.year + ';';
      dataForCsv += element.brand + ';';
      dataForCsv += element.gender + ';';
      dataForCsv += element.articleCode + ';';
      dataForCsv += element.category + ';';
      dataForCsv += element.product + ';';
      dataForCsv += element.description + ';';
      dataForCsv += element.color + ';';

      let dataForCsvImage = '';
      let nbImages = 0;
      element.images.forEach((image) => {

        if (image.selected) {

          dataForCsvImage += image.link + ';';
          if (image.realSelected) {
            dataForCsvImage += image.realLink + ';';
          } else {
            dataForCsvImage += ';';
          }
          nbImages++;
        }
      });
      dataForCsvImage += ';'.repeat(10 - nbImages);
      dataForCsv += dataForCsvImage + '\r\n';
    });

    return dataForCsv;
  }

  updateSelection(lineIndex: number, imageIndex: number, selected: boolean) {

    this.data[lineIndex].images[imageIndex].selected = selected;
  }

  updateRealSelection(lineIndex: number, imageIndex: number, selected: boolean) {

    this.data[lineIndex].images[imageIndex].realSelected = selected;
  }

  public addColumn(name: string) {

    const exist = this.selectedColumns.find((item) => item === name);
    if (!exist) {

      this.selectedColumns.push(name);
    }
  }

  public removeColumn(name: string) {

    const index = this.selectedColumns.indexOf(name, 0);
    if (index > -1) {

      this.selectedColumns.splice(index, 1);
    }
  }

  public getSelectedColumns() {

    return this.selectedColumns;
  }

  public pushNbMedias(nbMedias: number) {

    this.mediasQueue.next(nbMedias);
  }

  public onNbMediasChange() {

    return this.mediasQueue.asObservable();
  }

  public pushScrappingDone() {

    this.scrappingQueue.next(true);
  }

  public onScrappingDone() {

    return this.scrappingQueue.asObservable();
  }
}
