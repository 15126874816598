<div class="spinner-wrapper">

  <mat-form-field style="padding: 10px">
    <mat-label>Rechercher dans</mat-label>
    <mat-select [(ngModel)]="selectedCountry">
      <mat-option *ngFor="let country of countries" [value]="country.code">
        {{ country.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field style="padding: 10px">
    <mat-label>Produits à échantillonner</mat-label>
    <input matInput [(ngModel)]="nbSamples" type="number">
  </mat-form-field>

  <mat-form-field style="padding: 10px">
    <mat-label>Nombre de médias maximum</mat-label>
    <input matInput [(ngModel)]="nbMedias" type="number" (change)="onChange()">
  </mat-form-field>

  <button [disabled]="!uploadDone" mat-raised-button color="accent" (click)="launch(true)">
    Echantillonnage
  </button>&nbsp;

  <button [disabled]="!uploadDone" mat-raised-button color="accent" (click)="launch(false)">
    Lancer le scrapping
  </button>&nbsp;

  <button [disabled]="!scrappingDone" mat-raised-button color="accent" (click)="download()">
    Downloader le fichier
  </button>&nbsp;

  <mat-spinner [hidden]="spinnerHidden" diameter="30"></mat-spinner>

</div>