// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googleApiUrl: 'https://www.googleapis.com/customsearch/v1',
  googleApiKey: 'AIzaSyChYpFHN7N1CvTfKLV1aG-BCepsvUj64e4',
  googleApiSearchCx: 'eafa3ec7076ecbcab',
  exempleDeRequete: 'https://www.googleapis.com/customsearch/v1?key=AIzaSyAAnyDthw81gyns13byTF_ekFJrMW_KGxs&cx=3857a680ca352c307&startIndex=0&num=5&searchType=image&q=Crash%20romain%20grosjean'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
